.card-invader {
	float: right;
	 max-width: 250px;
	 text-align: center;
}
.timeline-inverted .card-invader {
	float: left;
}
.card.card-timeline .timeline>li>.timeline-badge {
	margin-top: 20px;
	height: 30px;
	width: 30px;
	margin-left: -15px;
	line-height: 29px;
}
.card.card-timeline .timeline>li>.timeline-panel:before{
	top: 35px;
}
.card.card-timeline .timeline>li>.timeline-panel:after{
	display: none;
}

 .card-invader .card-body .card-title {
	 margin-top: 0;
}
 .card-invader [class*=col-] .card-description {
	 margin-bottom: 0;
}
 .card-invader [class*=col-] .card-description + .card-footer {
	 margin-top: 8px;
}
 .card-invader .card-avatar {
	 max-width: 130px;
	 max-height: 130px;
	 margin: -60px auto 0;
}
 .card-invader .card-avatar img {
	 border-radius: 50% !important;
}
 .card-invader .card-avatar + .card-body {
	 margin-top: 15px;
}
 .card-invader.card-plain .card-avatar {
	 margin-top: 0;
}
 .card-invader .card-body .card-avatar {
	 margin: 0 auto 30px;
}
 .card-invader.card-horizontal .card-image .img {
	 border-top-right-radius: 0 !important;
	 border-bottom-right-radius: 0 !important;
	 border-bottom-left-radius: 0.2857rem !important;
}
 .card-invader .card-image {
	 position: relative;
}
 .card-invader .card-image .title {
	 position: absolute;
	 left: 20px;
	 top: 10px;
}
 .card-invader .card-image .dropdown {
	 position: absolute;
	 right: 10px;
	 top: 10px;
}
 .card-invader .card-image img {
 	 width:100%;
	 border-bottom-left-radius: 0 !important;
	 border-bottom-right-radius: 0 !important;
}
 .card-invader .card-image .title, .card-invader .card-image .dropdown {
	 z-index: 2;
}
 .card-invader .card-image .title .btn, .card-invader .card-image .dropdown .btn {
	 color: white;
}
 /*.card-invader .card-image:after {
	 position: absolute;
	 top: 0;
	 bottom: 0;
	 left: 0;
	 height: 100%;
	 z-index: 1;
	 width: 100%;
	 display: block;
	 content: "";
	 background: rgba(0, 0, 0, .6);
	 border-top-left-radius: 0.2857rem !important;
	 border-top-right-radius: 0.2857rem !important;
}*/
 .card-invader .card-image.no-mask:after {
	 background: none;
}
 .card-invader hr {
	 margin-right: 0;
	 margin-left: auto;
}
 .card-invader .invader-title {
	 text-align: left;
	 margin: 0px 7px 0px 7px;
}
.card-invader .status-pill-container {
	float: right;
	margin: 7px;
}
.card-invader .invader-subtitle {
	text-align: left;
	color: hsla(0, 0%, 100%, .6);
	margin: 7px;
}
.white-content .card-invader .invader-subtitle {
	color: #1d253b;
}
 .card-invader .table {
	 margin-bottom: 0;
}
 .card-invader .table td {
	 border: none;
	 padding: 7px;
	 padding-right: 0;
}
 .card-invader .table td i {
	 margin-right: 5px;
	 font-size: 17px;
	 color: #4444b8;
	 margin-top: -3px;
}
 .card-invader[data-background="full"] img {
	 height: 100%;
	 width: 100%;
}
 .card-invader[data-background="full"] .card-image {
	 position: absolute;
	 top: 0;
	 width: 100%;
	 height: 100%;
}
 .card-invader[data-background="full"] .card-body {
	 position: absolute;
	 bottom: 15px;
	 background: white;
	 width: 88%;
	 margin: 0 20px;
	 z-index: 2;
	 border-radius: 6px;
	 opacity: 0.9;
}
 .card-invader[data-background="full"] .card-body .invader-title, .card-invader[data-background="full"] .card-body .table td {
	 color: #4444b8 !important;
}
 .card-invader .card-footer {
	 color: #fff;
	 padding: 15px;
	 margin-top: 0;
}
.white-content .card-invader .card-footer {
	color: #1d253b;
}
 .card-invader.profile-bg .card-header {
	 background-position: center center;
	 background-size: cover;
	 margin-bottom: 60px;
	 width: 100%;
	 height: 150px;
}
 .card-invader.profile-bg .card-header .card-avatar {
	 margin: 70px auto 0;
}
 .card-invader.profile-bg .card-header .card-avatar .img {
	 border: 2px solid #fff;
}
 .card-invader.profile-bg .card-body {
	 margin-top: 25px;
}
@media (max-width:767.98px) {
	.card-invader {
		float:left;
	}
}
.linear-gradient-background {
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
}

.card-city-name {
    margin: 0px;
    color: #fff;
}

.comments-source {
	font-style: italic;
	color: #CCC;
	font-size: 0.7rem;
	padding-top: 15px;
}

.cityCollectionViewCell {
	overflow: hidden;
	padding: 0px;
	color: #fff;
}
.cityCollectionViewCell .card-img-overlay {
	padding: 0px;
}
.cityCollectionViewCell .card-body {
	padding: 10px;
	color: #fff;
}
.cityCollectionViewCell .card-body h1 {
	font-weight: 600;
	font-size: 15px;
	color: #fff;
	margin: 0;
	padding-top: 0px;
}
.cityCollectionViewCell .card-body h2 {
	font-weight: 300;
	font-size: 10px;
	color: #fff;
	margin: 0;
	padding-top: 10px;
}
.cityCollectionViewCell .card-body h3 {
	font-weight: 300;
	font-size: 10px;
	color: #fff;
	margin: 0;
	padding-top: 5px;
}
.cityCollectionViewCell .card-body p {
	padding-left: 10px;
	margin: 0;
	padding: 0;
	color: #fff;
	font-size: 12px;
}

.invaderCollectionViewCell {
	overflow: hidden;
	padding: 0px;
	margin: 0px;
	width: 300px;
	float: right;
}
.timeline-inverted .invaderCollectionViewCell {
	float: left;
}
@media (max-width:767.98px) {
	.timeline .invaderCollectionViewCell {
		float: left;
	}
}

.invaderCollectionViewCell .card {
	margin: 0px;
	height: 100px;
	text-align: left;
}

.invaderCollectionViewCell .row {
	margin: 0px;
}

.invaderCollectionViewCell .card .img-fluid {
	height: 100px;
	width: 100px;
	object-fit: cover;
}

.invaderCollectionViewCell .card-body {
	padding: 10px;
	margin: 0px;
	width: 200px;
	height: 100px;
}
.invaderCollectionViewCell .card-body .card-title {
	font-weight: 500;
	margin-bottom: 0.2em;
}
.invaderCollectionViewCell .card-body h5 {
	font-weight: 300;
	color: #999;
}
.invaderCollectionViewCell .card-body h6 {
	font-size: 12px;
	font-weight: 300;
	color: #CCC;
	text-transform: none;
}
.card.card-timeline .timeline h6 {
	color: #CCC;
}
.invaderCollectionViewCell .card-body .btn.btn-icon {
	height: 20px;
	width: 20px;
	min-width: 20px;
	font-size: 10px;
}
.invaderCollectionViewCell .card-body .btn {
	background-image: none;
	background: #DDD;
}
.invaderCollectionViewCell .card-body .btn.btn-active {
	background-image: none;
	background: #4444B8;
}

.cityHeader {
	overflow: hidden;
	max-height: 200px;
	padding: 0px;
}
.cityHeader .card-img-overlay {
	padding: 0px;
}
.cityHeader .card-img-overlay {
	padding: 0px;
}

.cityHeader .card-body {
	padding: 0px;
	color: #fff;
}
.cityHeader .card-body table td {
	padding: 10px 20px 10px 20px;
	color: #fff;
}
.cityHeader .card-body h1 {
	font-weight: 600;
	font-size: 26px;
	color: #fff;
	margin: 0;
	padding-top: 0px;
}
.cityHeader .card-body h2 {
	font-weight: 300;
	font-size: 14px;
	color: #fff;
	margin: 0;
	padding-top: 10px;
}
.cityHeader .card-body h3 {
	font-weight: 300;
	font-size: 14px;
	color: #fff;
	margin: 0;
	padding-top: 5px;
}
.cityHeader .card-body p {
	padding-left: 10px;
	margin: 0;
	padding: 0;
	color: #fff;
	font-size: 14px;
}

.card-body .btn.btn-icon {
	height: 22px;
	width: 22px;
	min-width: 22px;
	font-size: 12px;
}
.card-body .btn-inactive, .navbar .navbar-nav>a.btn-inactive {
	background-image: none;
	background: #DDD;
}
.card-body .btn-active, .navbar .navbar-nav>a.btn-active {
	background-image: none;
	background: #4444B8;
}

.white-content .table>tbody>tr>td, .white-content .table>tbody>tr>th, .white-content .table>tfoot>tr>td, .white-content .table>tfoot>tr>th, .white-content .table>thead>tr>td, .white-content .table>thead>tr>th {
	padding: 0px;
	vertical-align: top;
}

.modal.modal-mini .modal-dialog {
	max-width: 340px;
}
.modal.modal-default .form-control {
	border-color: #AAA;
	background-color: #EEE;
	color: #AAA;
	width: 100%;
}
.modal.modal-default label {
	margin-top: 10px;
	font-size: 13px;
}
.modal-content .modal-body {
	line-height: normal;
}
.white-content .modal.modal-mini .modal-footer button {
	margin-top: 25px;
}
.modal-content .modal-body+.modal-footer {
	background-color: #DDD;
}

.card-live {
	overflow: hidden;
}

.card-live .card-body {
	padding: 0px;
}
.card-live .live-title {
	padding: 10px;
	margin: 0px;
	line-height: 1em;
	font-size: 1rem;
	font-weight: 600;
}
.card-live .live-subtitle {
	padding: 10px;
	margin: 0px;
	text-align: right;
	line-height: 1em;
	color: #CCC;
}

.card-profile .table-responsive {
	overflow: hidden;
}

/******************
/* Invader profile
/******************

/* Dropdown status */

.btn-unknown {
	border-color: #888;
	box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)
}

.btn-unknown:hover {
	color: #fff;
	background-color: #d9d9d9;
	border-color: #eeeeee
}

.btn-unknown.focus,
.btn-unknown:focus {
	box-shadow: 0 4px 6px rgba(94, 94, 94, 0.11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(164, 164, 164, 0.5)
}

.btn-unknown.disabled,
.btn-unknown:disabled {
	color: #fff;
	background-color: #d9d9d9;
	border-color: #eeeeee
}

.btn-unknown:not(:disabled):not(.disabled).active,
.btn-unknown:not(:disabled):not(.disabled):active,
.show>.btn-unknown.dropdown-toggle {
	color: #fff;
	background-color: #d9d9d9;
	border-color: #eeeeee
}

.btn-unknown:not(:disabled):not(.disabled).active:focus,
.btn-unknown:not(:disabled):not(.disabled):active:focus,
.show>.btn-unknown.dropdown-toggle:focus {
	box-shadow: 0 0 0 0 rgba(172, 172, 172, 0.5)
}

.btn-definitivelyDead {
	border-color: #888;
	box-shadow: 0 4px 6px rgba(85, 85, 85, 0.11), 0 1px 3px rgba(0, 0, 0, .08)
}

.btn-definitivelyDead:hover {
	color: #fff;
	background-color: #000000;
	border-color: #000000
}

.btn-definitivelyDead.focus,
.btn-definitivelyDead:focus {
	box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(164, 164, 164, 0.5)
}

.btn-definitivelyDead.disabled,
.btn-definitivelyDead:disabled {
	color: #fff;
	background-color: #000000;
	border-color: #000000
}

.btn-definitivelyDead:not(:disabled):not(.disabled).active,
.btn-definitivelyDead:not(:disabled):not(.disabled):active,
.show>.btn-definitivelyDead.dropdown-toggle {
	color: #fff;
	background-color: #000000;
	border-color: #000000
}

.btn-definitivelyDead:not(:disabled):not(.disabled).active:focus,
.btn-definitivelyDead:not(:disabled):not(.disabled):active:focus,
.show>.btn-definitivelyDead.dropdown-toggle:focus {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5)
}

.card-profile .btn-dead {
	border: none;
	position: relative;
	overflow: hidden;
	margin: 4px 1px;
	border-radius: .4285rem;
	cursor: pointer;
	background: #CB0D25;
	background-image: linear-gradient(to bottom left, #CB0D25, #9e091c, #CB0D25);
	background-size: 210% 210%;
	background-position: 100% 0;
	transition: all .15s ease;
	box-shadow: none;
	color: #fff;
	background-color: #CB0D25;
}

.card-profile .btn-dead:hover {
	border: none;
	position: relative;
	overflow: hidden;
	margin: 4px 1px;
	border-radius: .4285rem;
	cursor: pointer;
	background: #CB0D25;
	background-image: linear-gradient(to bottom left, #CB0D25, #6f0512, #CB0D25);
	background-size: 210% 210%;
	background-position: 100% 0;
	transition: all .15s ease;
	box-shadow: none;
	color: #fff;
	background-color: #bb0000;
	border-color: #800000
}

.card-profile .btn-dead.focus,
.card-profile .btn-dead:focus {
	box-shadow: 0 4px 6px rgba(217, 31, 31, 0.11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(182, 0, 0, 0.5)
}

.card-profile .btn-dead.disabled,
.card-profile .btn-dead:disabled {
	border: none;
	position: relative;
	overflow: hidden;
	margin: 4px 1px;
	border-radius: .4285rem;
	cursor: pointer;
	background: #CB0D25;
	background-image: linear-gradient(to bottom left, #CB0D25, #6f0512, #CB0D25);
	background-size: 210% 210%;
	background-position: 100% 0;
	transition: all .15s ease;
	box-shadow: none;
	color: #fff;
	background-color: #000000;
	border-color: #000000
}

.card-profile .btn-dead:not(:disabled):not(.disabled).active,
.card-profile .btn-dead:not(:disabled):not(.disabled):active,
.card-profile .show>.btn-dead.dropdown-toggle {
	border: none;
	position: relative;
	overflow: hidden;
	margin: 4px 1px;
	border-radius: .4285rem;
	cursor: pointer;
	background: #CB0D25;
	background-image: linear-gradient(to bottom left, #CB0D25, #6f0512, #CB0D25);
	background-size: 210% 210%;
	background-position: 100% 0;
	transition: all .15s ease;
	box-shadow: none;
	color: #fff;
	background-color: #bb0000;
	border-color: #800000
}

.card-profile .btn-dead:not(:disabled):not(.disabled).active:focus,
.card-profile .btn-dead:not(:disabled):not(.disabled):active:focus,
.card-profile .show>.btn-dead.dropdown-toggle:focus {
	border: none;
	position: relative;
	overflow: hidden;
	margin: 4px 1px;
	border-radius: .4285rem;
	cursor: pointer;
	background: #CB0D25;
	background-image: linear-gradient(to bottom left, #CB0D25, #6f0512, #CB0D25);
	background-size: 210% 210%;
	background-position: 100% 0;
	transition: all .15s ease;
	box-shadow: none;
	box-shadow: 0 0 0 0 rgba(182, 0, 0, 0.5)
}

.btn-unflashable {
	border: none;
	position: relative;
	overflow: hidden;
	margin: 4px 1px;
	border-radius: .4285rem;
	cursor: pointer;
	background: #F3AF22;
	background-image: linear-gradient(to bottom left, #F3AF22, #ac7b16, #F3AF22);
	background-size: 210% 210%;
	background-position: 100% 0;
	transition: all .15s ease;
	box-shadow: none;
	border-color: #F3AF22;
	box-shadow: 0 4px 6px rgba(87, 68, 0, 0.11), 0 1px 3px rgba(116, 97, 0, 0.08)
}

.btn-unflashable:hover {
	color: #fff;
	background-color: #F3AF22;
	border-color: #F3AF22
}

.btn-unflashable.focus,
.btn-unflashable:focus {
	box-shadow: 0 4px 6px rgba(217, 182, 31, 0.11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(182, 137, 0, 0.5)
}

.btn-unflashable.disabled,
.btn-unflashable:disabled {
	color: #fff;
	background-color: #000000;
	border-color: #000000
}

.btn-unflashable:not(:disabled):not(.disabled).active,
.btn-unflashable:not(:disabled):not(.disabled):active,
.show>.btn-unflashable.dropdown-toggle {
	color: #fff;
	background-color: #F3AF22;
	border-color: #F3AF22
}

.btn-unflashable:not(:disabled):not(.disabled).active:focus,
.btn-unflashable:not(:disabled):not(.disabled):active:focus,
.show>.btn-unflashable.dropdown-toggle:focus {
	box-shadow: 0 0 0 0 rgba(182, 142, 0, 0.5)
}

.btn-alive {
	border: none;
	position: relative;
	overflow: hidden;
	margin: 4px 1px;
	border-radius: .4285rem;
	cursor: pointer;
	background: #008E44;
	background-image: linear-gradient(to bottom left, #008E44, #02552a, #008E44);
	background-size: 210% 210%;
	background-position: 100% 0;
	transition: all .15s ease;
	box-shadow: none;
	border-color: #008E44;
	box-shadow: 0 4px 6px rgba(0, 87, 32, 0.11), 0 1px 3px rgba(0, 116, 12, 0.08)
}

.btn-alive:hover {
	border: none;
	position: relative;
	overflow: hidden;
	margin: 4px 1px;
	border-radius: .4285rem;
	cursor: pointer;
	background: #008E44;
	background-image: linear-gradient(to bottom left, #008E44, #02552a, #008E44);
	background-size: 210% 210%;
	background-position: 100% 0;
	transition: all .15s ease;
	box-shadow: none;
	color: #fff;
	background-color: #008E44;
	border-color: #008E44
}

.btn-alive.focus,
.btn-alive:focus {
	box-shadow: 0 4px 6px rgba(31, 217, 61, 0.11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 0 rgba(0, 182, 24, 0.5)
}

.btn-alive.disabled,
.btn-alive:disabled {
	border: none;
	position: relative;
	overflow: hidden;
	margin: 4px 1px;
	border-radius: .4285rem;
	cursor: pointer;
	background: #008E44;
	background-image: linear-gradient(to bottom left, #008E44, #02552a, #008E44);
	background-size: 210% 210%;
	background-position: 100% 0;
	transition: all .15s ease;
	box-shadow: none;
	color: #fff;
	background-color: #008E44;
	border-color: #008E44
}

.btn-alive:not(:disabled):not(.disabled).active,
.btn-alive:not(:disabled):not(.disabled):active,
.show>.btn-alive.dropdown-toggle {
	border: none;
	position: relative;
	overflow: hidden;
	margin: 4px 1px;
	border-radius: .4285rem;
	cursor: pointer;
	background: #008E44;
	background-image: linear-gradient(to bottom left, #008E44, #02552a, #008E44);
	background-size: 210% 210%;
	background-position: 100% 0;
	transition: all .15s ease;
	box-shadow: none;
	color: #fff;
	background-color: #008E44;
	border-color: #008E44
}

.btn-alive:not(:disabled):not(.disabled).active:focus,
.btn-alive:not(:disabled):not(.disabled):active:focus,
.show>.btn-alive.dropdown-toggle:focus {
	border: none;
	position: relative;
	overflow: hidden;
	margin: 4px 1px;
	border-radius: .4285rem;
	cursor: pointer;
	background: #008E44;
	background-image: linear-gradient(to bottom left, #008E44, #02552a, #008E44);
	background-size: 210% 210%;
	background-position: 100% 0;
	transition: all .15s ease;
	box-shadow: 0 0 0 0 rgba(0, 142, 40, 0.47)
}

.modal-content .modal-body+.modal-footer { /* Filter Footer */
	background-color: #FFF;
}

.results {
	font-size: 1.3em;
	margin-top: 9px;
}

.navbar .navbar-wrapper { /* to fix navbar height in order to map container displaying correctly) */
	display: flex;
	align-items: center;
	height: 46px;
}
